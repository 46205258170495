import React from 'react';
import { language } from '../assets/lang';
import { functionsEU } from '../components/firebase/firebase';
import Wallet from '../assets/images/icons/wallet.svg';
import Orders from '../assets/images/icons/orders.svg';
import Wristband from '../assets/images/icons/linkwristband.svg';
import Offline from '../assets/images/icons/pinnen.svg';
import PaymentIcons from './paymenticons';
import Share from '../assets/images/icons/whatsapp.svg';
import Gift from '../assets/images/icons/gift.svg';
import Return from '../assets/images/icons/return.svg';
import Headphones from '../assets/images/icons/headphones.svg';
import ReturnCup from '../assets/images/icons/returncup.svg';

class Transactions extends React.Component {
render() {	
    return (	
		<div style={{paddingLeft: 20, paddingRight: 20}}>
            <div style={{marginTop: 35}} onClick={() => this.props.changeEventState('')}>
                <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
            </div>
            <p style={{fontSize: 20, marginTop: 20, marginBottom: 35, color: '#22293b', fontFamily: 'Kentledge-bold', }}>{language.yourtransactions}</p>
			{!this.state.loaded ?
			<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 100, paddingBottom: 100}}>	
				<img src={require('../assets/images/indicator.gif')} resizeMode='contain' style={{width: 60, height: 60, marginBottom: 30}}/>		
			</div>
			:
			<div>					
				{this.state.transacties.map((item,index) => (
				<div onClick={() => this.opentransaction(item.id)} key={index} className='transaction'>
					<div style={{display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
							<div style={{flexDirection: 'row', justifyContent: 'center', width: 33, marginLeft: -2}}>
							{item.type === 'order' ? 
								<div>
									{item.data.wristband ? 
                                    <img src={Wristband} style={{width: 26, height: 26}} />
									:
                                    <img src={Orders} style={{width: 26, height: 26}} />
									}
								</div>
							:null}
							{item.type === 'onlinetrans' ? <PaymentIcons name={this.props.eventData.paymentMethods.find(m => m.id === item.data.orderData.method).img} width={26} height={26} /> : null}
							{item.type === 'offlinetrans' ? <img src={Offline} style={{width: 32, height: 32}} /> : null}
							{item.type === 'sharetrans' ? <img src={Share} style={{width: 26, height: 26}} />  : null}
							{item.type === 'transpromotion' ? <img src={Gift} style={{width: 26, height: 26}} />  : null}
							{item.type === 'returntrans' ? <img src={Return} style={{width: 26, height: 26}} />  : null}
							{item.type === 'returncups' ? <img src={ReturnCup} style={{width: 26, height: 26}} />  : null}
							{item.type === 'headphone' ? <img src={Headphones} style={{width: 26, height: 26}} />  : null}
							</div>
							<div key={index} style={{marginLeft: 17, width: 150}}>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<p style={{fontSize: 16, color: '#22293b', fontFamily: 'Kentledge-bold', }}>{item.type === 'order' ? language.drinkfood : null}{item.type === 'onlinetrans' ? language.onlinetrans : null}{item.type === 'offlinetrans' ? language.offlinetrans : null}{item.type === 'sharetrans' ? language.sharetrans : null}{item.type === 'transpromotion' ? language.transpromotion : null}{item.type === 'returntrans' ? language.returntrans : null}{item.type === 'returncups' ? language.cupreturntrans : null}{item.type === 'headphone' ? language.headphones : null}</p> 
								</div>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 3, marginTop: -1}}>
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', marginTop: 1, fontFamily: 'Kentledge'}}>{this.formattime(item.data.tijd)}</p>	
								</div>
								{item.data.status === 'opgehaald' ?		
									<div>	
										{item.data.wristband ? 
										<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.wristbandOrder}</p>	
										:
										<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.pickedup} {item.data.friendItems === 'isfriend' ? (item.data.pickupby ? (language.by + ' ' + item.data.pickupby) : language.byfriend) : ''}</p>	
										}
									</div>
								:null}
								{item.data.status === 'geannuleerd' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.tokensreturned}</p>
								:null}
								{item.data.status === 'ophalen' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.orderready}</p>
								:null}
								{item.data.status === 'bereiden' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.preparingorder}</p>
								:null}
								{item.data.status === 'partypas' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.paidwithppass}</p>
								:null}
								{item.data.status === 'open' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.paymentcreated}</p>
								:null}
								{item.data.status === 'paidoffline' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.transactionpaid}</p>
								:null}
								{item.data.status === 'PAID' || item.data.status === 'paid' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.transactionpaid}</p>
								:null}
								{item.data.status === 'PENDING' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.waitforpaym}</p>
								:null}
								{item.data.status === 'EXPIRED' || item.data.status === 'expired' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.transexpired}</p>
								:null}		
								{item.data.status === 'CANCEL' || item.data.status === 'canceled' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.transcanceled}</p>
								:null}
								{item.data.status === 'FAILURE' || item.data.status === 'DENIED' || item.data.status === 'REJECTED' || item.data.status === 'failed' ?								
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.somethingwrong}</p>
								:null}	
								{item.data.status === 'REFUNDING' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.returntransproc}</p>
								:null}
								{item.data.status === 'REFUND' || item.data.status === 'PARTIAL_REFUND' || item.data.status === 'refunded' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.returntranspaid}</p>
								:null}
								{item.data.status === 'Geincasseerd' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.incassopaid}</p>
								:null}
								{item.data.status === 'Terugboeking' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.incassocancel}</p>
								:null}
								{item.data.status === 'Verwerkt' || item.data.status === 'Toegevoegd' || item.data.status === 'In batch' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.incassoproces}</p>
								:null}
								{item.data.status === 'failed' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.returntransfailed}</p>
								:null}
								{item.data.status === 'sharerecieved' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.transsharerecieved} {item.data.sender ? item.data.sender : language.friend}</p>
								:null}	
								{item.data.status === 'sharesend' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.transsharesend} {item.data.reciever ? item.data.reciever : language.friend}</p>
								:null}	
								{item.data.status === 'promo' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.transpromo}</p>
								:null}
								{item.data.status === 'cupsreturned' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.cupsreturned}</p>
								:null}
								{item.data.status === 'headphonesRented' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.headphonesrented}</p>
								:null}
								{item.data.status === 'headphonesReturned' ?
									<p style={{fontSize: 12, color: 'rgba(0,0,0,0.8)', marginTop: -1, fontFamily: 'Kentledge'}}>{language.headphonesreturned}</p>
								:null}
							</div>	
						</div>	
						{this.lockerAmount(item) > 0 ?
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingRight: 5, paddingLeft: 5}}>
							<p style={{fontSize: 22, color: item.data.status === 'PAID' || item.data.status === 'paid' ? '#4cc15f' : 'rgba(0,0,0,0.2)', fontFamily: 'Kentledge-bold', marginTop: 4, marginRight: 4}}>+{this.lockerAmount(item)}</p>
							<p style={{fontSize: 10, color: 'rgba(0,0,0,0.7)', fontFamily: 'Kentledge', marginTop: 4, marginRight: 4}}>{this.lockerAmount(item) === 1 ? language.locker : language.lockers}</p>
						</div>
						:
						<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: 5, paddingLeft: 5}}>
							{this.isPositiv(item) === 'green' ?
							<p style={{fontSize: 22, color: '#4cc15f', fontFamily: 'Kentledge-bold', marginTop: 4, marginRight: 4}}>+{this.tokenAmount(item)}</p>
							:null}
							{this.isPositiv(item) === 'red' ?
							<p style={{fontSize: 22, color: '#b32e5d', fontFamily: 'Kentledge-bold', marginTop: 4, marginRight: 4}}>{this.tokenAmount(item) < 0 ? '' : '-'}{this.tokenAmount(item)}</p>
							:null}
							{this.isPositiv(item) === 'grey' ?
							<p style={{fontSize: 22, color: 'rgba(0,0,0,0.2)', fontFamily: 'Kentledge-bold', marginTop: 4, marginRight: 4}}>{this.tokenAmount(item)}</p>
							:null}
							<p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', marginTop: -4, marginRight: 4}}>{this.tokenAmount(item) === 1 ? language.token : language.tokens}</p>
						</div>
						}
						<div>
							{this.state.opentrans.includes(item.id) ?
                            <i class="fa fa-chevron-down" style={{fontSize: 12, color: item.type !== 'sharetrans' && item.type !== 'transpromotion' && item.type !== 'returncups' && item.type !== 'returnheadphone' ? 'rgba(0,0,0,0.7)' : '#F9F9F9'}}></i>
							:
                            <i class="fa fa-chevron-right" style={{fontSize: 12, color: item.type !== 'sharetrans' && item.type !== 'transpromotion' && item.type !== 'returncups' && item.type !== 'returnheadphone' ? 'rgba(0,0,0,0.7)' : '#F9F9F9'}}></i>
							}
						</div>
					</div>

					{item.type === 'headphone' && item.data.headphoneData && this.state.opentrans.includes(item.id) ?
					<div style={{marginTop: 20, width: '100%'}}>
						<div style={{width: '100%'}}>
							<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
							<div style={{display: 'flex', width: '100%', paddingTop: 12, paddingBottom: 12, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
								<p style={{fontSize: 11.5, width: window.innerWidth -185, color: '#22293b', fontFamily: 'Kentledge', marginTop: 0}}>{item.data.headphoneData.amount} {language.headphone}</p>
								<div style={{display: 'flex',flexDirection: 'row', justifyContent: 'flex-end', alignItems:'center', width: 100}}>
									<p style={{fontSize: 11.5, color: '#22293b', fontFamily: 'Kentledge', }}>{item.data.headphoneData.price} {item.data.headphoneData.price === 1 ? language.token : language.tokens}</p>
								</div>
							</div>
						</div>
						<div style={{width: '100%'}}>
							<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
							<div style={{display: 'flex', width: '100%', paddingTop: 12, paddingBottom: 12, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
								<p style={{fontSize: 11.5, width: window.innerWidth -185, color: '#22293b', fontFamily: 'Kentledge', marginTop: 0}}>{item.data.headphoneData.amount} {language.deposit}</p>
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems:'center', width: 100}}>
									<p style={{fontSize: 11.5, color: '#22293b', fontFamily: 'Kentledge', }}>{item.data.headphoneData.deposit} {item.data.headphoneData.deposit === 1 ? language.token : language.tokens}</p>
								</div>
							</div>
						</div>
					</div>
					:null}

					{item.type === 'order' && this.state.opentrans.includes(item.id) ?
					<div style={{marginTop: 20, width: '100%'}}>
						{item.data.items.map((userItem, index1) => (
							<div key={index1} style={{width: '100%'}}>
								<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
								<div style={{display: 'flex', width: '100%', paddingTop: 12, paddingBottom: 12, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
									<p style={{fontSize: 11.5, color: '#22293b', fontFamily: 'Kentledge', marginTop: 0}}>{userItem.aantal} {userItem.naam}</p>
									<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems:'center'}}>
										<p style={{fontSize: 11.5, color: '#22293b', fontFamily: 'Kentledge', }}>{parseFloat(userItem.prijs * userItem.aantal)} {parseFloat(userItem.prijs * userItem.aantal) === 1 ? language.token : language.tokens}</p>
									</div>
								</div>
							</div>
						))}	
						{item.data.friendItems && item.data.friendItems !== 'isfriend' ?
						<div>
							{item.data.friendItems.map((friendItem, index2) => (
								<div key={index2} style={{width: '100%'}}>
									<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
									<div style={{display: 'flex', width: '100%', paddingTop: 12, paddingBottom: 12, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
										<p style={{fontSize: 11.5, color: '#22293b', fontFamily: 'Kentledge', marginTop: 0}}>{friendItem.aantal} {friendItem.naam}</p>
										<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems:'center'}}>
											<p style={{fontSize: 11.5, color: '#22293b', fontFamily: 'Kentledge', }}>{language.paidby} {friendItem.friendName ? friendItem.friendName : language.friend}</p>
										</div>
									</div>
								</div>
							))}	
						</div>
						:null}
						<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
						<div style={{display: 'flex', width: '100%', paddingTop: 20, flexDirection: 'row', justifyContent: 'center'}}>
							<p style={{fontSize: 15, color: '#22293b', fontFamily: 'Kentledge', marginTop: 0}}>{language.bestelnum}: </p>
							<p style={{fontSize: 15, color: '#22293b', fontFamily: 'Kentledge-bold'}}> {item.id}</p>
						</div>	
					</div>
					:null}
					{item.type === 'onlinetrans' && this.state.opentrans.includes(item.id) || item.type === 'offlinetrans' && this.state.opentrans.includes(item.id) || item.type === 'returntrans' && this.state.opentrans.includes(item.id) ?
					<div style={{width: '100%', marginTop: 20}}>
						{Object.keys(item.data.orderData).map((orderItem, itemIndex) => {
							if(orderItem === 'tokens' && item.data.orderData['tokens'].aantal !== 0){
								return <div key={itemIndex}>
									<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
									<div style={{display: 'flex', width: '100%', paddingTop: 9, paddingBottom: 9, flexDirection: 'row', justifyContent: 'space-between'}}>
										<p style={{fontSize: 13, color: '#22293b', fontFamily: 'Kentledge'}}>{item.data.orderData['tokens'].aantal} {item.data.orderData['tokens'].aantal === 1 ? language.token : language.tokens}</p>
										<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: 75}}>
											<p style={{fontSize: 13, color: '#22293b', fontFamily: 'Kentledge', }}>{'\u20AC'} {item.data.orderData['tokens'].kosten.toFixed(2).toString().replace(".", ",")}</p>
										</div>
									</div>
								</div>
							}
							if(orderItem === 'locker'){
								return <div key={itemIndex}>
									{item.data.orderData['locker'].aantal > 0 ?
									<div>
										<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
										<div style={{display: 'flex', width: '100%', paddingTop: 9, paddingBottom: 9, flexDirection: 'row', justifyContent: 'space-between'}}>
											<p style={{fontSize: 13, color: '#22293b', fontFamily: 'Kentledge'}}>1 {language.locker}</p>
											<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: 75}}>
												<p style={{fontSize: 13, color: '#22293b', fontFamily: 'Kentledge', }}>{'\u20AC'} {item.data.orderData['locker'].kosten.toFixed(2).toString().replace(".", ",")}</p>
											</div>
										</div>
									</div>
									:null}
								</div>
							}
						})}

						{item.data.transfee !== null ?
						<div>
							<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
							<div style={{display: 'flex', width: '100%', paddingTop: 9, paddingBottom: 9, flexDirection: 'row', justifyContent: 'space-between'}}>
								<div style={{display: 'flex', flexDirection: 'row'}}>
									<p style={{fontSize: 13, color: '#22293b', fontFamily: 'Kentledge'}}>{item.type === "return" ? language.processcost : language.servicecosts}</p>
								</div>
								<div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: 75}}>
									<p style={{fontSize: 13, color: '#22293b', fontFamily: 'Kentledge'}}>{'\u20AC'} {parseFloat(item.data.transfee).toFixed(2).toString().replace(".", ",")}</p>
								</div>
							</div>
						</div>
						:null}

						<div style={{width: '100%', height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
						<div style={{display: 'flex', width: '100%', paddingTop: 20, flexDirection: 'row', justifyContent: 'center'}}>
							<p style={{fontSize: 15, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.total} </p>
							<p style={{fontSize: 15, color: '#22293b', fontFamily: 'Kentledge', marginTop: 0}}>{'\u20AC'} {item.data.bestelprijs ? item.data.bestelprijs.toString().replace(".", ",") : '0,00'}</p>
						</div>	
						{item.data.collectPayment ?
						<p style={{fontSize: 14, marginTop: 20, color: '#b32e5d', fontFamily: 'Kentledge', pAlign: 'center'}}>{language.duetonegative}</p>
						:null}
					</div>
					:null}
				</div>
				))}
				{this.state.transacties.length === 0 ?
					<div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(0,0,0,0.02)', marginTop: 25, paddingLeft: 15, paddingRight: 15, paddingTop: 50, paddingBottom: 70, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
						<img src={Wallet} style={{width: 30, height: 30}} />
						<p style={{fontSize: 13, marginTop: 20, color: 'rgba(0,0,0,0.6)', fontFamily: 'Kentledge', pAlign: 'center'}}>{language.noordersyet}</p>	
					</div>	
				:null}
			</div>	
			}
			<div style={{height: 40}}></div>
		</div>
    )
}

state = {transacties: [], loaded: false, opentrans: [], error: false}

componentDidMount() {this.laadtransacties()}

laadtransacties(){
    var getCardOders = functionsEU.httpsCallable('getCardOders');
    getCardOders({uid: this.props.userData.id, eid: this.props.eventData.eventid}).then(result => { 
        console.log(result);
        if(result.data.state === 1){
            this.setState({transacties: result.data.orderData, loaded: true, opentrans: [result.data.orderData[0].id]});
        }
        else{this.setState({error: true, loaded: true})}
    }).catch(e => {console.log(e); this.setState({error: true, loaded: true})});
}

formattime(tijd){
	var datum = new Date(0);
	datum.setUTCSeconds(tijd);
	var minuten = datum.getMinutes();
	if(datum.getMinutes() < 10){minuten = '0' + datum.getMinutes()}
	var timestamp = ("0" + datum.getDate()).slice(-2) + ' ' + language.monthsshort[datum.getMonth()] + ' ' + datum.getHours() + ':' + minuten;
	return timestamp;
}

tokenAmount(item){
	if(item.type === 'order'){return parseFloat(item.data.kosten)}
	return item.data.orderData['tokens'].aantal;
}

isPositiv(item){
	if(item.type === 'onlinetrans'){
		if(item.data.status === 'Geincasseerd' || item.data.status === 'PAID' && item.data.orderData['tokens'].aantal > 0 || item.data.status === 'paid' && item.data.orderData['tokens'].aantal > 0){return 'green'}
		return 'grey';
	}
	if(item.type === 'offlinetrans'){
		if(item.data.status === 'paidoffline'){return 'green'}
		return 'grey';
	}
	if(item.type === 'returncups'){return 'green'}
	if(item.type === 'headphone' && item.data.status === 'headphonesReturned'){return 'green'}
	if(item.type === 'sharetrans' && item.data.status === 'sharerecieved'){return 'green'}
	if(item.type === 'transpromotion'){return 'green'}
	return 'red';
}

lockerAmount(item){
	var lAmount = 0
	if(item.type === "onlinetrans" && item.data.orderData['tokens'].aantal === 0){lAmount = item.data.orderData.locker ? item.data.orderData.locker.aantal : 0}
	return lAmount;
}

opentransaction(id){
	var opentrans = this.state.opentrans;
	if(opentrans.includes(id)){
		var ind = opentrans.indexOf(id);
		opentrans.splice(ind, 1);
	}
	else{
		opentrans.push(id)
	}
	this.setState({opentrans: opentrans});
}

}

export default Transactions;