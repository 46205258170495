import React from 'react';
import { language } from '../assets/lang';

class Eventlist extends React.Component {
    render() {	
        return ( 	
        <div style={{marginBottom: 25, paddingBottom: 30, marginTop: 15, paddingLeft: 15, paddingRight: 15, width: '100%'}}>				
			<div style={{zIndex: 100}}>
				<div>			
					<div>
						<p style={{fontSize: 22, color: '#22293b', fontFamily: 'Kentledge-Bold'}}>{language.selectevent}</p>
						<p style={{fontSize: 14, color: '#22293b', fontFamily: 'Kentledge', marginTop: 3}}>{language.orlocation}</p>
					</div>
					<div style={{flexDirection: 'row', marginBottom: 25, marginTop: 20}}>
						<div>
							<div className='searchBarContainer'>
                                <i class="fa fa-magnifying-glass" style={{marginLeft: 15, marginTop: -3, color: 'rgba(0,0,0,0.6)'}}></i>
                                <input type='text' placeholder={language.searchevents} placeholderpColor="rgba(0,0,0,0.2)" className='searchBar' selectionColor={'#b32e5d'} onChange={(input) => {if(input.target.value.toLowerCase() === 'demo1'){this.props.goDemo()} this.setState({searchInput: input.target.value.toLowerCase()})}}/>
							</div>
						</div>
					</div>
				</div>
			</div>	
            {this.props.eventArray.slice().sort((a, b) => a.tijd - b.tijd).map((item, index) => {
                if(this.state.searchInput.length < 2 || this.state.searchInput.length >= 2 && item.naam.toLowerCase().includes(this.state.searchInput) || this.state.searchInput.length >= 2 && item.locatie.toLowerCase().includes(this.state.searchInput)){
                return <div key={index} style={{marginBottom: 15}}>
                    <div onClick={() => this.props.selectEvent(item)} className='eventItem'>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 100, borderTopLeftRadius: 7, borderTopRightRadius: 7, overflow:'hidden', backgroundColor: 'rgba(0,0,0,0.1)', paddingVertical: 12}}>
                            <img src={item.cover} style={{width: '100%'}} /> 
                        </div>	
                        <div style={{display: 'flex', position: 'absolute', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', top: 87, left: 0, paddingLeft: 10, paddingRight: 10, height: 24, borderTopRightRadius: 7, borderBottomRightRadius: 7, backgroundColor: '#b32e5d'}}>
                            <p style={{fontSize: 11, color: 'rgba(255,255,255,0.9)', marginLeft: 3, marginTop: 2, fontFamily: 'Kentledge'}}> {this.formatDate(item.tijd, item.type, item.dagen)} </p>
                        </div>	
                        <div style={{display: 'flex', paddingLeft: 20, paddingRight: 20, marginTop: 27, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center',}}>
                            <div>
                                <p style={{fontSize: 15, color: '#4a4a4a', fontFamily: 'Kentledge-Bold'}}>{item.naam}</p>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: -2}}>
                                    <p style={{fontSize: 13, color: 'rgba(0,0,0,0.4)', fontFamily: 'Kentledge'}}>{item.locatie}</p>										
                                </div>	
                            </div>
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div>
                </div>
                }
            })}									
            {this.props.eventArray.length === 0 ?
            <div style={{width: '100%', marginTop: 30, paddingHorizontal: 35, paddingTop: 60, paddingBottom: 80, borderRadius: 7, alignItems: 'center', justifyContent: 'center'}}>
                <p style={{fontSize: 13, marginTop: 15, color: 'rgba(0,0,0,0.6)', fontFamily: 'Kentledge', pAlign: 'center'}}>{language.nofutureevents}</p>	
            </div>			
            :null}
        </div>
        )
    }

    state = {searchInput: ''}

    startSearch(){
        this.setState({search: true});
        setTimeout(() => {this.searchInput.focus()}, 100);
    }
    
    stopSearch(){
        this.setState({searchInput: ''})
        if(this.searchInput){this.searchInput.clear()}
    }
    
    formatDate(tijd, type, dagen){
        if(type === 'event'){
            var date = new Date(0);
            date.setUTCSeconds(tijd);
            var maand = ' ' + language.months[date.getMonth()] + ' ';
            if(dagen && dagen > 1){
                var date2  = new Date(date);
                date2.setDate(date.getDate() + (dagen -1))
                if(date.getMonth() === date2.getMonth()){
                    maand = ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date.getMonth()] + ' ';
                }
                else{
                    maand = ' ' + language.months[date.getMonth()] + ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date2.getMonth()] + ' ';
                }
            }		
            return '' + date.getDate() + maand + date.getFullYear();
        }
        return language.eventlocation;
    }
  
    
    }
    
    export default Eventlist;