import React from 'react';
import { language } from '../assets/lang';
import PaymentIcons from './paymenticons';
import CreatePayment from './createPayment';
import Tokensale from '../assets/images/tokensale.svg';

class Payment extends React.Component {
    render() {	
        return ( 
        <div style={{width: '100%', height: '100%', paddingBottom: 30}}>				

            {this.state.afrekenState === 'tokens' ? 
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <div style={{marginTop: 35}} onClick={() => this.props.changeEventState('')}>
                    <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
				</div>
                <div style={{marginBottom: 20}}>
                    {this.props.eventData.tokenverkoop ?
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 20}}>
                        <p style={{fontSize: 20, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.howmanytokenuwant}</p>
                        <p style={{fontSize: 20, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.tobuy}</p>
                        <input type='number' inputMode='numeric' pattern="[0-9]*" value={this.state.afrekenObj.tokens.aantal === 0 ? '' : this.state.afrekenObj.tokens.aantal.toString()} ref={(input) => {this.tokenInput = input}} keyboardType='number-pad' selectionColor={'#b32e5d'} style={{marginTop: 30, color: this.state.tokenError !== '' ? '#b32e5d' : '#22293b', height: 50, justifyContent: 'center', paddingLeft: 20, borderRadius: 7, backgroundColor: 'rgba(0,0,0,0.04)', fontFamily: 'Kentledge', fontSize: 15, borderWidth: 0, borderColor:'rgba(0,0,0,0.1)'}} onChange={e => this.addTokens(e.target.value)} placeholderTextColor='rgba(0,0,0,0.2)' placeholder={language.atokencostseu} />
                    </div>
                    :
                    <div style={{display: 'flex', flexDirection: 'column', height: 200, justifyContent: 'center', alignItems: 'center'}}>
                        <i class="fa fa-triangle-exclamation" style={{fontSize: 35, color: '#b32e5d'}}></i>
                        <p style={{fontSize: 15, marginTop: 25, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.atthismomentno}</p>
                        <p style={{fontSize: 15, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.tokenssoldinapp}</p>
                    </div>
                    }
                </div>
                <div style={{width: '100%', marginTop: 25, marginBottom: 12, height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
                
                {this.props.eventData.tokenverkoop && this.state.tokenError === '' ?
                <div>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-end', paddingBottom: 55}}>
                        <p style={{fontSize: 16, marginTop: 10, color: '#22293b', fontFamily: 'Kentledge'}}>{language.total}</p>
                        <p style={{fontSize: 24, marginTop: 4, color: '#22293b', fontFamily: 'Kentledge-bold', textAlign: 'right'}}>{'\u20AC'} {(this.state.afrekenObj.costs + this.state.afrekenObj.serviceFee + this.state.afrekenObj.transFee).toFixed(2).replace(".", ",")}</p>
                        {this.props.eventData.pay.serviceFee.chargeUser ?
                        <div onPress={() => this.setState({transfeemodal: true})} style={{display: 'flex', flexDirection: 'row', marginTop: 15}}>
                            <p style={{fontSize: 12, color: 'rgba(0,0,0,0.5)', fontFamily: 'Kentledge', textAlign: 'right'}}>Incl. {'\u20AC'} {(this.state.afrekenObj.serviceFee + this.state.afrekenObj.transFee).toFixed(2).replace(".", ",")} {language.servicecosts}</p>
                            <div style={{display: 'flex', backgroundColor: 'rgba(0,0,0,0.07)', width: 17, height: 17, borderRadius: 8.5, marginLeft: 7, justifyContent: 'center', alignItems: 'center'}}><i class="fa fa-info" style={{color: 'rgba(0,0,0,0.5)', fontSize: 9}}></i></div>
                        </div>
                        :null}
                        {this.methodRetourAllowed() !== '' ?
                        <div style={{width: '100%'}}>
                            <div style={{width: '100%', marginTop: 25, marginBottom: 12, height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
                            <p style={{paddingLeft: 40, marginTop: 10, fontSize: 11, color: '#b32e5d', fontFamily: 'Kentledge', textAlign: 'right'}}>{this.methodRetourAllowed()}</p>
                        </div>
                        :null}
                        {this.firstPayment() !== '' ?
                        <div style={{width: '100%'}}>
                            <div style={{width: '100%', marginTop: 15, marginBottom: 12, height: 0, borderWidth: 0.5, borderColor: 'rgba(0,0,0,0.1)', borderStyle: 'dashed'}}></div>
                            <p style={{paddingLeft: 40, marginTop: 10, fontSize: 11, color: 'rgba(0,0,0,0.7)', fontFamily: 'Kentledge', textAlign: 'right'}}>{this.firstPayment()}</p>
                        </div>
                        :null}
                    </div>

                    <div onClick={() => this.selectPayment()} style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', marginBottom: 15}}>
                        <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 42, width: 180, borderRadius: 20}}>
                            <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge-bold'}}>{language.continue}</p>
                        </div>
                    </div>
                </div>
                :null}

                {this.props.eventData.tokenverkoop && this.state.tokenError !== '' ?
                    <p style={{fontSize: 14, marginTop: 15, color: '#b32e5d', fontFamily: 'Kentledge', textAlign: 'center'}}>{this.state.tokenError}</p>
                :null}
               
            </div>
            :null}

            {this.state.afrekenState === 'selectPayment' ? 
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <div style={{marginTop: 35}} onClick={() => this.setState({afrekenState: 'tokens'})}>
                    <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
				</div>
                <div style={{marginTop: 20, flexGrow: 0}} contentContainerStyle={{alignItems: 'center', paddingHorizontal: 20, paddingBottom: 30}}>
                    <div style={{width: '100%'}}>
                        <div style={{marginBottom: 20}}>
                            <p style={{fontSize: 20, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.howtopay}</p>
                        </div>
                        <div style={{display: 'flex', flex: 1, flexWrap: 'wrap', alignItems: 'stretch', justifyContent: 'space-between', flexDirection: 'row'}}>
                            {this.getPaymentMethods().map((method, index) => (
                            <div onClick={() => this.setState({method: index},()=>this.afrekenen())} style={{position: 'relative', display: 'flex', flexDirection: 'column', width: '48%', justifyContent: 'center', alignItems: 'center', height: 130, borderRadius: 10, marginTop: 15, backgroundColor: 'rgba(0,0,0,0.03)', borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: this.state.method === index ? '#4cc15f' : 'rgba(0,0,0,0.08)'}}>
                                <div style={{width: 60, alignItems: 'center'}}><PaymentIcons name={method.img} /></div>
                                <p style={{fontSize: 16, color: '#22293b', fontFamily: 'Kentledge-bold', textAlign: 'center', padding: 5}}>{method.name}</p>	
                                {this.state.method === index ?
                                <div style={{display: 'flex', position: 'absolute', right: 10, top: 10, width: 22, height:22, borderRadius: 11, backgroundColor: '#4cc15f', alignItems: 'center', justifyContent: 'center'}}>
                                    <i class="fa fa-check" style={{color: '#fff', fontSize: 12, marginTop: 2}}></i>
                                </div>	
                                :null}
                            </div>
                            ))}
                        </div>
                        {this.getPaymentMethods().length === 0 ? 
                        <div style={{paddingHorizontal: 40, width: '100%', alignItems: 'center'}}>
                            <i class="fa fa-triangle-exclamation" style={{color: 'rgba(0,0,0,0.6)'}}></i>
                            <p style={{fontSize: 14, marginTop: 15, color: '#22293b', fontFamily: 'Kentledge', textAlign: 'center'}}>{language.nopaymentmethods}</p>
                        </div>
                        :null}
                    </div>
                </div>
                {this.getPaymentMethods()[this.state.method].id === '10' ?
                <div onClick={() => this.setState({afrekenState: 'selectBank'},()=>{window.scrollTo(0, 0)})} style={{display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', marginTop: 50, marginBottom: 15}}>
                    <div style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'center', height: 42, width: 180, borderRadius: 20}}>
                        <p style={{fontSize: 14, color: '#fff', fontFamily: 'Kentledge-bold'}}>{language.selecturbank}</p>
                    </div>
                </div>
                :null}
                {this.getPaymentMethods()[this.state.method].id === 'pp1' || this.getPaymentMethods()[this.state.method].id === 'pp2' ?
                <div className='next_container2'>
                    <div style={{padding: 20, paddingBottom: 0, width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <img src={Tokensale} style={{width: 200, marginBottom: -22, objectFit: 'contain'}} />
                    </div>
                    <p style={{fontSize: 17, color: '#22293b', marginTop: 45, fontFamily: 'Kentledge-bold', textAlign: 'center'}} numberOfLines={1}>{language.cashorcard}</p>
                    <p style={{fontSize: 13, color: '#22293b', marginTop: 5, fontFamily: 'Kentledge', width: 220, textAlign: 'center'}}>{language.scancardtopup}</p>               
                    <div onClick={() => this.props.changeEventState('')} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 20}}>
                        <div style={{display: 'flex', flexDirection: 'row', backgroundColor: '#b32e5d', paddingLeft: 20, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between', height: 36, width: 140, borderRadius: 20}}>
                            <i class="fa fa-chevron-left" style={{color: '#fff'}}></i>
                            <p style={{fontSize: 12.5, color: '#fff', fontFamily: 'Kentledge'}}>{language.goback}</p>
                            <div></div>
                        </div>
                    </div>
                </div>
                :null}
                {this.getPaymentMethods()[this.state.method].id !== 'pp1' && this.getPaymentMethods()[this.state.method].id !== 'pp2' && this.getPaymentMethods()[this.state.method].id !== '10' ?
                <CreatePayment cardID={this.props.cardID} eventData={this.props.eventData} afrekenObj={this.state.afrekenObj} />
                :null}
            </div>
            :null}

            {this.state.afrekenState === 'selectBank' ? 
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                <div style={{marginTop: 35}} onClick={() => this.setState({afrekenState: 'selectPayment'})}>
                    <i class="fa fa-chevron-left" style={{color: 'rgba(0,0,0,0.6)'}}></i>
				</div>
                <p style={{fontSize: 20, marginTop: 20, marginBottom: 20, color: '#22293b', fontFamily: 'Kentledge-bold'}}>{language.selecturbank}</p>         
                <div style={{display: 'flex', flex: 1, flexWrap: 'wrap', alignItems: 'stretch', justifyContent: 'space-between', flexDirection: 'row'}}>
                    {this.props.eventData.idealBanks.map(bank => (
                        <div onClick={() => this.setState({bankid: bank.id},()=>this.afrekenen())} style={{position: 'relative', display: 'flex', flexDirection: 'column', width: '48%', justifyContent: 'center', alignItems: 'center', height: 130, borderRadius: 10, marginTop: 15, backgroundColor: 'rgba(0,0,0,0.03)', borderRadius: 10, borderStyle: 'solid', borderWidth: 1, borderColor: this.state.bankid === bank.id ? '#4cc15f' : 'rgba(0,0,0,0.08)'}}>
                            <PaymentIcons name={bank.img} />
                            <p style={{fontSize: 13, color: '#22293b', fontFamily: 'Kentledge-bold', letterSpacing: 0.5, marginTop: 10,}}>{bank.name}</p>
                            {this.state.bankid === bank.id ?
                            <div style={{display: 'flex', position: 'absolute', right: 10, top: 10, width: 22, height:22, borderRadius: 11, backgroundColor: '#4cc15f', alignItems: 'center', justifyContent: 'center'}}>
                                <i class="fa fa-check" style={{color: '#fff', fontSize: 12, marginTop: 2}}></i>
                            </div>	
                            :null}
                        </div>
                    ))}
                </div>
                <CreatePayment cardID={this.props.cardID} eventData={this.props.eventData} afrekenObj={this.state.afrekenObj} />
            </div>
            :null}

        </div>
        )
    }

    state = {afrekenState: 'tokens', tokenError: '', method: 0, bankid: '4', functionState: 0, afrekenObj: {tokens: {aantal: 0, kosten: 0}, locker: {aantal: 0, kosten: 0, id: '', date: 0}, costs: 0, serviceFee: 0, transFee: 0}}

    componentDidMount(){if(this.props.eventData.tokenverkoop){this.tokenInput.focus()}}

    addTokens(amount){
        if(amount < 1000){
            var muntmin = this.props.eventData.muntmin;
            var muntaantal = this.props.eventData.muntaantal && this.props.eventData.muntaantal.length > 0 ? true : false;
            var tokenError = '';
            if(muntmin && muntmin > amount && amount !== 0 && !muntaantal){tokenError = language.mintokens + muntmin + ' ' + language.tokens}
            var orderObj = this.state.afrekenObj;
            orderObj['tokens'].aantal = parseFloat(amount);
            this.setState({tokenError: tokenError, afrekenObj: orderObj},()=>this.afrekenen());
        }
    }

    afrekenen(){
        var paymentType = this.getPaymentMethods()[this.state.method];
        var afrekenenObj = this.state.afrekenObj;
        var orderCosts = 0;
        var serviceFee = 0;
        var transFee = 0;
    
        if(afrekenenObj.tokens.aantal > 0){
            orderCosts = orderCosts + afrekenenObj.tokens.aantal * parseFloat(this.props.eventData.muntprijs);
        }
        if(orderCosts !== 0 && this.props.eventData.pay.serviceFee.chargeUser){
            var service = this.props.eventData.pay.serviceFee;
            serviceFee = parseFloat((service.start + (((orderCosts)/100)*service.percentage)).toFixed(2));
            transFee = parseFloat((paymentType.base + (((orderCosts + serviceFee)/100)*paymentType.per)).toFixed(2));
        }
    
        afrekenenObj['bankId'] = this.state.bankid;
        afrekenenObj['method'] = paymentType.id;
        afrekenenObj['serviceFee'] = serviceFee;
        afrekenenObj['transFee'] = transFee;
        afrekenenObj['costs'] = orderCosts;
        
        this.setState({afrekenenObj: afrekenenObj});
    }

    selectPayment(){
        if(this.state.afrekenObj.tokens.aantal > 0){
            this.setState({afrekenState: 'selectPayment'})
        }
        else{alert(language.fillamountfirst)}
    }

    getPaymentMethods(){
        var paymentMethods = this.props.eventData.paymentMethods.filter(mtd => mtd.status && !mtd.hide);
        if(paymentMethods && paymentMethods.length > 0){return paymentMethods}
        return [];
    }

    firstPayment(){
        var krediet = this.props.eventData.krediet;
        var allowCollect = this.props.eventData.paymentMethods.find(m => m.id === this.props.eventData.paymentMethods[this.state.method].id).allowCollect;
        var paymentInfo = this.props.eventData.paymentInfo && this.props.eventData.paymentInfo.collectMethod;
        if(krediet && allowCollect && !paymentInfo){return language.withfirsttransincs}
        return '';
    }
    
    methodRetourAllowed(){
        if(this.props.eventData.muntretour.allowed){
            if(this.props.eventData.paymentMethods[0].retour){
                if(this.props.eventData.muntretour.verval === 'periode'){
                    var days = this.props.eventData.muntretour.periode > 1 ? language.days : language.day
                    return language.canreturntokenstill + ' ' + this.props.eventData.muntretour.periode + ' ' + days + language.afterpurch;
                }
                if(this.props.eventData.muntretour.verval === 'datum'){
                    return language.canreturntokenstill + ' ' + this.formatDate2(this.props.eventData.muntretour.date);
                }
                return language.retuncoinsallowed;
            }
            return language.methodretournotallowed;
        }
        return language.cantreturncoins;
    }    
  
    formatDate(tijd, type, dagen){
        if(type === 'event'){
            var date = new Date(0);
            date.setUTCSeconds(tijd);
            var maand = ' ' + language.months[date.getMonth()] + ' ';
            if(dagen && dagen > 1){
                var date2  = new Date(date);
                date2.setDate(date.getDate() + (dagen -1))
                if(date.getMonth() === date2.getMonth()){
                    maand = ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date.getMonth()] + ' ';
                }
                else{
                    maand = ' ' + language.months[date.getMonth()] + ' ' + language.tm + ' ' + date2.getDate() + ' ' + language.months[date2.getMonth()] + ' ';
                }
            }		
            return '' + date.getDate() + maand + date.getFullYear();
        }
        return language.eventlocation;
    }


    formatDate2(dag, dayonly){
        var date = new Date(0);
        date.setUTCSeconds(dag);
        if(dayonly){return language.weekdays[date.getDay()]}
        return language.weekdays[date.getDay()] + ' ' + ("0" + date.getDate()).slice(-2) + ' ' + language.monthsshort[date.getMonth()] + ' ' + date.getFullYear();
    }
    
}
    
    export default Payment;